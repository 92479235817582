import React from 'react'
import Intro from '../../img/intro.png';
import videobgmp4 from '../../img/background.mp4'
import videobgwebm from '../../img/background.webm'
import poster from '../../img/poster.jpeg'

function Video() {
    return (
        <div className='landing__video'>
            <div className='landing__video__wrapper'>
                <div className="landing__video__container">
                    <video  
                        role="presentation" 
                        preload="auto" 
                        playsinline="" 
                        crossorigin="anonymous" 
                        loop muted autoplay="" 
                        className="landing__video__vid"
                        poster={poster}
                    >
                        <source src={videobgwebm} type="video/webm" />
                        <source src={videobgmp4} type="video/mp4" />
                    </video>
                    <div className="landing__video__text">
                        <div className="landing__video__text__main">
                            Spark your <span>big innovation</span> today
                        </div>
                        <div className="landing__video__text__sub">
                            Compete for funding to turn your ideas into reality.
                        </div>
                        <div className="landing__video__btnsection">
                            <button className='btn landing__video__btn'>Learn More</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="landing__intro">
                <div className="landing__intro__text">
                    Afro Innovation is an innovation ecosystem that provides training, networks, recognition
                    and funding to interdisciplinary teams originating from Ghana who have transformative solutions
                    to real-world problems.
                </div>
                <div className="landing__intro__img">
                    <img src={Intro} alt="A Creative brain" />
                </div>
            </div>
        </div>
    )
}

export default Video