import React from 'react'
import Rodal from 'rodal'
import { isMobile } from 'react-device-detect';

function Privacy(props) {
    return (

        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.82 : 800}
            height={isMobile ? 540 : 670}
            customStyles={{ overflowY: 'scroll' }}
        >
            <div className='terms'>
                <h3>Privacy Policy</h3>

                <div className="terms__container">
                    <h4>1. Introduction</h4>
                    <p>AfroInnovationsSummit is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and disclose your personal information when you interact with our website, services, or participate in our competitions.</p>

                    <h4>2. Information Collection</h4>
                    <p>We may collect the following types of personal information from you:</p>
                    <ul>
                        <li><h5>Information you provide:</h5> This includes information you voluntarily submit, such as your name, email address, contact information, and competition submissions.</li>
                        <li><h5>Information collected automatically:</h5> We may automatically collect information about your use of our website, such as your IP address, browser type, and browsing history.</li>
                        <li><h5>Information from third parties:</h5> We may obtain information about you from third-party sources, such as social media platforms.</li>
                    </ul>

                    <h4>3. Information Usage</h4>
                    <p>We may use your personal information for the following purposes:</p>
                    <ul>
                        <li><h5>To process your participation in our competitions.</h5></li>
                        <li><h5>To communicate with you about your submissions and the competition results.</h5></li>
                        <li><h5>To provide you with information about our website, services, and other relevant updates.</h5></li>
                        <li><h5>To analyze and improve our website and services.</h5></li>
                        <li><h5>To comply with legal requirements and protect our rights.</h5></li>
                    </ul>

                    <h4>4. Information Sharing</h4>
                    <p>We may share your personal information with:</p>
                    <ul>
                        <li><h5>Third-party service providers:</h5> We may engage third-party service providers to assist us in providing our services. These providers may have access to your personal information to perform their functions on our behalf.</li>
                        <li><h5>Business partners:</h5> We may share your personal information with business partners who collaborate with us on specific initiatives.</li>
                        <li><h5>Legal authorities:</h5> We may disclose your personal information to law enforcement agencies or other legal authorities as required by law.</li>
                    </ul>

                    <h4>5. Data Security</h4>
                    <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>

                    <h4>6. Your Rights</h4>
                    <p>You may have certain rights regarding your personal information, such as the right to access, correct, or delete your information. If you wish to exercise these rights, please contact us using the information provided below.</p>

                    <h4>7. Children's Privacy</h4>
                    <p>Our website and services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that we have collected personal information from a child under the age of 13, please contact us immediately.</p>

                    <h4>8. Changes to This Privacy Policy</h4>
                    <p>We may update this Privacy Policy from time to time. Any changes will be effective upon posting on our website. Your continued use of our services after such changes indicates your acceptance of the amended policy.</p>

                    <h4>9. Contact Information</h4>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at support@afroinnovationsummit.com or 0501430711.</p>

                    By using our website or services, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.

                </div>

                <div className="terms__btnsection">
                    <button className="btn terms__btn" onClick={() => props.hideModal()}>Close</button>
                </div>
            </div>
        </Rodal>
    )
}

export default Privacy