import React from 'react'
import Logo from '../img/logo.png'

function Footer() {
  return (
    <div className='footer'>
      <div className="footer__img">
        <img src={Logo} alt="Logo of Company" />
      </div>
        <div className="footer__list">
            <ul>
                <li>SITEMAP</li>
                <li>TERMS OF SERVICE</li>
                <li>PRIVACY POLICY</li>
                <li>COOKIES</li>
                <li>CONTACT US</li>
            </ul>
        </div>
        <div className="footer__madeby">
            Powered and made with love by HWA INC.
        </div>
    </div>
  )
}

export default Footer