import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Rodal from 'rodal'
import { AppDataContext } from '../../Contexts/AppDataContext'
import { Footer, Spinner } from '../../_shared'
import HomeHeader from './HomeHeader'
import { IoLockClosed, IoMail, IoArrowDown } from 'react-icons/io5'
import { ToastContainer, toast } from 'react-toastify';
import { FaLanguage, FaLock, FaUnlock } from "react-icons/fa6";
import { RiLockPasswordFill, RiDeleteBin7Fill, RiContactsBook2Fill } from "react-icons/ri";
import { MdLanguage } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
import Menu from './Menu';
import { updatePassword, deleteUser } from './dbFunctions'

const inlineStyle = {
  display: 'none',
  width: '0'
}

const LANGUAGE_LIST = ['English', 'English']
const CONTENTNAMES = ['Language', 'Password', 'Delete Account', 'Contact Us']

function Settings() {

  const [showMenu, setShowMenu] = useState(false)
  const [content, setContent] = useState(0)
  const [phoneMenu, setPhoneMenu] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const [oldPword, setOldPword] = useState("")
  const [newPword, setNewPword] = useState("")
  const [retypePword, setRetypePword] = useState("")

  const [delPword, setDelPword] = useState("")

  const { userData, setUserData } = useContext(AppDataContext)
  const navigate = useNavigate()

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to reload the page? You may lose data.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    //console.log(content)
    renderContent()
  }, [content])


  const notify = (message) => toast.success(message, { position: 'top-center' });

  const setError = (message) => toast.error(message, { position: 'top-center' })

  function handleUpdatePassword() {

    setShowSpinner(true)

    if (oldPword === userData.password) {
      if (newPword === retypePword) {

        if (newPword === userData.password) {
          setShowSpinner(false);
          setError("New password cannot be the same as the old password.");

        } else {
          if (newPword.length > 8) {
            updatePassword(userData, newPword, notify, setError, setUserData, setShowSpinner)
          } else {
            setShowSpinner(false)
            setError("Password too weak")
          }
        }

      } else {
        setShowSpinner(false)
        setError("Password don't match")
      }
    } else {
      setShowSpinner(false)
      setError("Old Password wrong")
    }
  }

  function handleDeleteAcc() {
    setShowSpinner(true)
    if (delPword === userData.password) {
      deleteUser(userData, navigate, notify, setShowSpinner)
    } else {
      setShowSpinner(false)
      setError("Wrong password")
    }
  }

  function renderContent() {

    switch (content) {
      case 0:
        return <Language />

      case 1:
        return <Password
          oldPword={oldPword}
          setOldPword={setOldPword}
          newPword={newPword}
          setNewPword={setNewPword}
          retypePword={retypePword}
          setRetypePword={setRetypePword}
          handleSubmit={handleUpdatePassword}
        />

      case 2:
        return <DeleteAccount
          delPword={delPword}
          setDelPword={setDelPword}
          handleSubmit={handleDeleteAcc}
        />

      case 3:
        return <ContactUs />

      default:
        break;
    }
  }

  return (
    <div className='settings'>
      <HomeHeader
        setShowMenu={setShowMenu}
        showMenu={showMenu}
        setShowChat={setShowChat}
        showChat={showChat}
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        userData={userData}
      />
      <Menu
        showMenu={showMenu}
        userData={userData}

      />
      <ToastContainer />

      <Spinner
        height="50vh"
        showSpinner={showSpinner}
      />
      <div className="settings__container" style={showSpinner ? inlineStyle : {}}>
        <div className="settings__label">
          <ul>
            <li onClick={() => setContent(0)}>
              <FaLanguage className='settings__label__icon' />
              <span>Language</span>
            </li>
            <li onClick={() => setContent(1)}>
              <RiLockPasswordFill className='settings__label__icon' />
              <span>Password</span>
            </li>
            <li onClick={() => setContent(2)}>
              <RiDeleteBin7Fill className='settings__label__icon' />
              <span>Delete Account</span>
            </li>
            <li onClick={() => setContent(3)}>
              <RiContactsBook2Fill className='settings__label__icon' />
              <span>Contact Us</span>
            </li>
          </ul>
        </div>

        <div className="settings__label__phone">
          <p>Menu</p>

          <div
            className="settings__label__phone__main"
            onClick={() => setPhoneMenu(true)}
          >
            <span>{CONTENTNAMES[content]}</span>
            <IoArrowDown size={20} />
          </div>

          <DropDownMenu
            showModal={phoneMenu}
            hideModal={() => setPhoneMenu(false)}
            content={content}
            setContent={setContent}
          />

        </div>

        {
          renderContent()
        }



      </div>
      <Footer />
    </div>
  )
}

export default Settings

const DropDownMenu = (props) => {
  return (
    <Rodal
      visible={props.showModal}
      onClose={() => props.hideModal()}
      width={window.innerWidth * 0.8}
      height={200}
    >
      <div className="settings__label__phone__dd">
        {
          CONTENTNAMES.map((item, index) =>
            <p
              className={item === CONTENTNAMES[props.content] ? 'settings__label__phone__dd__selected' : ''}
              onClick={() => {
                props.setContent(index)
                props.hideModal()
              }}
            >
              {item}
            </p>)
        }
      </div>
    </Rodal>
  )
}

const Language = (props) => {
  return (
    <div className="settings__main">
      <h3>Select Language</h3>

      <div className="settings__main__container">

        <p>Language</p>
        <div className="settings__input__box">
          <div className="settings__input__iconbox">
            <MdLanguage className="settings__input__icon" />
          </div>
          <select
            type="text"
            className='settings__input'
            name="mail"
            id='mail'
          >
            {
              LANGUAGE_LIST.map((item, index) => {
                return (
                  <option
                    value={item}
                    hidden={!index}
                  >
                    {item}
                  </option>
                )
              })
            }
          </select>

        </div>

        <div className="settings__main__btnsection">
          <button className="btn settings__main__btn">Change Language</button>
        </div>

      </div>
    </div>
  )
}

const Password = (props) => {
  return (
    <div className="settings__main">
      <h3>Set New Password</h3>
      <div className="settings__main__container">

        <div className="settings__input__wrap">
          <label htmlFor="oldpword">Old Password</label>
          <div className="settings__input__box">
            <div className="settings__input__iconbox">
              <FaUnlock className="settings__input__icon" />
            </div>

            <input
              type="password"
              className="settings__input"
              name="oldpword"
              value={props.oldPword}
              onChange={(e) => props.setOldPword(e.target.value)}
            />
          </div>
        </div>

        <div className="settings__input__wrap">
          <label htmlFor="newpword">New Password</label>
          <div className="settings__input__box">
            <div className="settings__input__iconbox">
              <FaLock className="settings__input__icon" />
            </div>

            <input
              type="password"
              className="settings__input"
              name="newpword"
              id="newpword"
              value={props.newPword}
              onChange={(e) => props.setNewPword(e.target.value)}
            />
          </div>
        </div>

        <div className="settings__input__wrap">
          <label htmlFor="retypepword">Retype New Password</label>
          <div className="settings__input__box">
            <div className="settings__input__iconbox">
              <IoLockClosed className="settings__input__icon" />
            </div>

            <input
              type="password"
              className="settings__input"
              name="retypepword"
              id="retypepword"
              value={props.retypePword}
              onChange={(e) => props.setRetypePword(e.target.value)}
            />
          </div>
        </div>

        <div
          className="settings__main__btnsection"
          onClick={() => props.handleSubmit()}
        >
          <button className="btn settings__main__btn">Change Password</button>
        </div>

      </div>
    </div>
  )
}

const DeleteAccount = (props) => {
  return (
    <div className="settings__main">
      <h3>Delete Your Account</h3>
      <div className="settings__main__container">
        <p>You can delete your account and all associated data. Note that deleting your account is permanent and cannot be undone.</p>
        <p>Please Enter your password to delete the account</p>
        <div className="settings__input__wrap">
          <label htmlFor="delpword">Type Password</label>
          <div className="settings__input__box">
            <div className="settings__input__iconbox">
              <FaLock className="settings__input__icon" />
            </div>

            <input
              type="password"
              className="settings__input"
              name="delpword"
              id="delpword"
              value={props.delPword}
              onChange={(e) => props.setDelPword(e.target.value)}
            />
          </div>
        </div>

        <div
          className="settings__main__btnsection"
          onClick={() => props.handleSubmit()}
        >
          <button className="btn settings__main__btn">Delete Account</button>
        </div>

      </div>
    </div>
  )
}

const ContactUs = (props) => {
  return (

    <div className="settings__main">
      <h3>Contact Us</h3>
      <div className="settings__main__container">

        <div className="settings__contact__item">
          <BsTelephoneFill className='settings__contact__item__icon' />
          <div className="settings__contact__item__details">
            <h4>Phone Number 1</h4>
            <p>030 156 3258</p>
          </div>
        </div>

        <div className="settings__contact__item">
          <MdPhoneInTalk className='settings__contact__item__icon' />
          <div className="settings__contact__item__details">
            <h4>Phone Number 2</h4>
            <p>024 199 2899</p>
          </div>
        </div>

        <div className="settings__contact__item">
          <IoMail className='settings__contact__item__icon' />
          <div className="settings__contact__item__details">
            <h4>Email</h4>
            <p>support@afroinnovation.com</p>
          </div>
        </div>

      </div>

      <div className="settings__main__btnsection">
        <button className="btn settings__main__btn">Send Email</button>
      </div>
    </div>
  )
}