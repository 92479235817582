import "./sass/main.scss";
import "rodal/lib/rodal.css";
import "react-toastify/dist/ReactToastify.css";
import Navigator from "./Navigator";
import { getFirestore } from "firebase/firestore";
import { isMobile } from "react-device-detect";
import { AppDataProvider } from "./Contexts/AppDataContext";
import Logo from "./img/logo.png";
import "aos/dist/aos.css";
import AOS from "aos";

function App() {
  const db = getFirestore();
  //console.log(db);
  AOS.init();
  return (
    <AppDataProvider>
      <div className="App">
        <Navigator />
      </div>
    </AppDataProvider>
  );
}

export default App;
