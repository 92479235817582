import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppDataContext } from '../Contexts/AppDataContext';

const ProtectedRoute = () => {
  const { loggedIn } = useContext(AppDataContext);

  return loggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;