import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getFirestore, doc, updateDoc, deleteDoc } from "firebase/firestore";
import _ from "lodash";

async function uploadPDFtoFirebaseStorage(
  file,
  userData,
  setShowSpinner,
  setSuccess,
  setError,
  hideModal,
  setUser
) {
  const uid = userData.id;
  const storage = getStorage();
  const fileName = `${uid}-${file.name}`;
  const storageRef = ref(storage, `pdfs/${fileName}`);

  try {
    const uploadTask = uploadBytes(storageRef, file);

    await uploadTask;

    // Upload complete
    const downloadURL = await getDownloadURL(storageRef);

    // Update user's data in Firestore
    const firestore = getFirestore();
    const userRef = doc(firestore, "users", uid);
    await updateDoc(userRef, {
      pdfLink: downloadURL,
    });

    //console.log("PDF link updated successfully:", downloadURL);
    setUser({ ...userData, pdfLink: downloadURL });
    setShowSpinner(false);
    setSuccess("File upload successful");
    hideModal();
  } catch (error) {
    setShowSpinner(false);
    setError("Error uploading file");
    console.error("Error uploading file:", error);
  }
}

async function updateUserProfile(
  userData,
  file,
  setShowSpinner,
  setSuccess,
  setError,
  setUser
) {
  const firestore = getFirestore();
  const userRef = doc(firestore, "users", userData.id);

  try {
    if (file) {
      // User changed profile picture
      const storage = getStorage();
      const fileName = `${userData.id}-${file.name}`;
      const storageRef = ref(storage, `profileImages/${fileName}`);

      const uploadTask = uploadBytes(storageRef, file);
      await uploadTask;

      const downloadURL = await getDownloadURL(storageRef);
      userData.imgurl = downloadURL;
    }

    // Update user data in Firestore, regardless of whether the image was updated
    let dbUser = _.cloneDeep(userData);
    delete dbUser.id;
    await updateDoc(userRef, dbUser);
    setUser(userData);
    setShowSpinner(false);
    setSuccess("Profile Update Successful");
    //console.log("User profile updated successfully");
  } catch (error) {
    setShowSpinner(false);
    setError("Error uploading Profile");
    console.error("Error updating user profile:", error);
  }
}

async function updatePassword(
  user,
  newPassword,
  setSuccess,
  setError,
  setUser,
  setShowSpinner
) {
  
  const db = getFirestore();
  try {
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
      password: newPassword,
    });
    
    setUser({ ...user, password: newPassword });
    setShowSpinner(false);
    setSuccess("Password updated successfully");
    // console.log('Password updated successfully.');
  } catch (error) {
    setShowSpinner(false);
    setError("Error updating password");
    
    // console.error('Error updating password:', error);
    // throw error;
  }
}

async function deleteUser(userData, navigate, setError, setShowSpinner) {
  const db = getFirestore();
  const storage = getStorage();

  try {
    const userRef = doc(db, "users", userData.id);

    if (userData.imgurl) {
      const imageRef = ref(storage, userData.imgurl);
      await deleteObject(imageRef);
    }

    await deleteDoc(userRef);
    setShowSpinner(true);
    navigate("/");
    // console.log('User deleted successfully.');
  } catch (error) {
    setShowSpinner(false)
    setError("Error deleting user");
    // console.error('Error deleting user:', error);
    // throw error;
  }
}


export { uploadPDFtoFirebaseStorage, updateUserProfile, updatePassword, deleteUser };
