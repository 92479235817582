import React, { useState } from 'react'
import Rodal from 'rodal'
import { IoMdMail } from "react-icons/io";
import { sendForgotMail } from './dbFunctions';
import { ClipLoader } from 'react-spinners';
import { isMobile } from 'react-device-detect';

const fadePage = {
    display: 'none',
    width: '0',
}

function EmailDropdown(props) {

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        const myForm = document.getElementById("resetform")
        sendForgotMail(email, props.setSuccess, props.setError, myForm, setLoading)
    }

    return (
        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.8 : 700}
            height={400}
            closeMaskOnClick={false}
        >

            <div className='emaildd'>
                <h3>PASSWORD RESET</h3>

                <ClipLoader
                    color="blue"
                    loading={loading}
                    size={80}
                    className='emaildd__loader'
                />

                <form
                    action=""
                    onSubmit={handleSubmit}
                    className='emaildd__container'
                    style={loading ? fadePage : {}}
                    id="resetform"
                >
                    <p>We would send an email to help you reset the password if the account exists</p>
                    <div className="emaildd__inputbox">
                        <label htmlFor="email">Email</label>
                        <div className="emaildd__input__wrap">
                            <div className="emaildd__input__icon__box">
                                <IoMdMail className="emaildd__input__icon" />
                            </div>
                            <input
                                type="email"
                                className='emaildd__input'
                                name="email"
                                id='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="emaildd__btnsection">
                        <button className="btn emaildd__btn">Submit</button>
                    </div>
                </form>

            </div>
        </Rodal>
    )
}

export default EmailDropdown

const Loader = (props) => {
    <div
        className="emaildd__loader"
        style={props.loading ? {} : fadePage}
    >

    </div>
}