import React, { createContext, useState } from 'react'

const AppDataContext = createContext();

const AppDataProvider = ({children}) => {
    const [userData, setUserData] = useState({})
    const [message, setMessage] = useState({})
    const [showSpinner, setShowSpinner] = useState(true)
    const [noWifi, setNoWifi] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false)

    return (
        <AppDataContext.Provider 
            value={{ userData, setUserData, showSpinner, 
                setShowSpinner, noWifi, setNoWifi, loggedIn, setLoggedIn,
                message, setMessage
             }}
        >
            {children}
        </AppDataContext.Provider>
    )
}

export {AppDataContext, AppDataProvider};