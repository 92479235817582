import React, { useState } from 'react'
import { DOMAINSLIST } from '../../_shared/Constants'
import DomainDropdown from './DomainDropdown'

function Domains() {

    const [showDropDown, setShowDropDown] = useState(false)
    const [selected, setSelected] = useState(DOMAINSLIST[1])

    function handleLearnMore(item) {
        setSelected({
            title: item.title,
            imgClass: item.imgClass,
            intro: item.intro,
            listhead: item.listhead,
            list: item.list,
            color: item.color,
        })
        setShowDropDown(true)
    }

    return (
        <div className='landing__domains'>
            {
                showDropDown &&
                <DomainDropdown
                    domain={selected}
                    showModal={showDropDown}
                    hideModal={() => setShowDropDown(false)}
                />
            }

            <h3>Domains</h3>

            <div className='landing__domains__container'>

                {
                    DOMAINSLIST.map((item, index) => (

                        <DomainCard
                            item={item}
                            handleLearnMore={handleLearnMore}
                        />
                    ))
                }

            </div>
        </div>
    )
}

export default Domains

const DomainCard = (props) => {
    return (
        <div className='landing__domains__card' data-aos="fade-up" data-aos-duration="1500">
            <div
                className={`landing__domains__card__background ${props.item.imgClass}`}
            >

            </div>
            <div className="landing__domains__card__title">
                {props.item.title}
            </div>

            <div className="landing__domains__card__btnsection">
                <button
                    className='btn landing__domains__btn'
                    onClick={() => props.handleLearnMore(props.item)}
                >
                    Learn More
                </button>
            </div>
        </div>
    )
}