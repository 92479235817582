import React from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from '../../img/logo.png'
import User from '../../img/person.png';
import { AiFillMessage } from "react-icons/ai";
import { TbHelpOctagonFilled } from "react-icons/tb";

function HomeHeader(props) {

    const navigate = useNavigate()


    return (
        <div className="home__header">
            <div 
                className="home__header__logo"
                onClick={() => navigate('/home')}
            >
                <img src={Logo} alt="Logo of company" />
            </div>

            <div className="home__header__user">
                <p>{props.userData.firstName}</p>

                <TbHelpOctagonFilled 
                    className='home__header__user__help'
                    onClick={() => props.setShowHelp(!props.showHelp)}
                />

                <AiFillMessage 
                    className='home__header__user__messages'
                    onClick={() => props.setShowChat(!props.showChat)}
                />

                <div 
                    className="home__header__user__avatar"
                    onClick={() => props.setShowMenu(!props.showMenu)}
                >
                    <img src={props.userData.imgurl || User} alt="Avatar of User" />
                </div>

            </div>
        </div>
    )
}

export default HomeHeader