import React, { useEffect, useState, useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import CurrEvent from '../../img/Domains1.jpg';
import Year1 from '../../img/Domains2.jpg';
import Year2 from '../../img/Domains3.jpg'
import Year3 from '../../img/Domains4.png'
import { Footer } from '../../_shared'
import HomeHeader from './HomeHeader';
import RegisterDropdown from './RegisterDropdown';
import Menu from './Menu';
import Chatroom from './Chatroom';
import Help from './Help'
import { ToastContainer, toast } from 'react-toastify';

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
        // notify("Signed In Success")
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = 'Are you sure you want to reload the page? You may lose data.';
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    const [showRegisterDropdown, setShowRegisterDropdown] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [showChat, setShowChat] = useState(false)
    const [showHelp, setShowHelp] = useState(false)

    const {userData, setUserData} = useContext(AppDataContext)
    //console.log(userData)

    const notify = (message) => toast.success(message, { position: 'top-center' });

    const setError = (message) => toast.error(message, { position: 'top-center' })

    return (
        <>
            <div className='home'>
                <ToastContainer />

                <RegisterDropdown
                    showModal={showRegisterDropdown}
                    hideModal={() => setShowRegisterDropdown(false)}
                    userData={userData}
                    setUserData={setUserData}
                    setError={setError}
                    setSuccess={notify}
                />

                <Help 
                    showModal={showHelp}
                    hideModal={() => setShowHelp(false)}
                />

                <HomeHeader
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    setShowChat={setShowChat}
                    showChat={showChat}
                    showHelp={showHelp}
                    setShowHelp={setShowHelp}
                    userData={userData}
                />

                <Menu 
                    showMenu={showMenu}
                    userData={userData}
                    
                />
                <Chatroom
                    showChat={showChat}
                    closeChat={() => setShowChat(false)}
                />

                <div className="home__landing">
                    &nbsp;
                </div>

                <div className="home__current">
                    <div className="home__current__notes">
                        <h3>Ideas Clash -  Startup pitching competition</h3>
                        <h4>Embark on your global startup journey at the maiden edition of AFRO INNOVATION,
                            Ghana's premier startup pitch competition!
                        </h4>
                        <p>
                            The maiden edition of the Innovation Hackathon promises to be an exciting event, bringing together talented individuals from diverse backgrounds.
                            We anticipate a vibrant atmosphere filled with creativity and collaboration as participants work together to develop innovative solutions to pressing challenges.
                        </p>
                        <p>
                            The maiden edition of the Innovation Hackathon promises to be an exciting event, bringing together talented individuals from diverse backgrounds.
                            We anticipate a vibrant atmosphere filled with creativity and collaboration as participants work together to develop innovative solutions to pressing challenges.
                        </p>
                    </div>
                    <div className="home__current__card">
                        <div className="home__current__card__img">
                            <img src={CurrEvent} alt="Representation of current edition" />
                        </div>

                        <div className="home__current__card__title">
                            Maiden Edition of Afro Innovation
                        </div>

                        <div className="home__current__card__btnsection">
                            <button
                                className='btn home__current__card__btn'
                                onClick={() => {
                                userData.pdfLink ?  console.log('')  : setShowRegisterDropdown(true)
                                }}
                            >
                                {userData.pdfLink ? 'Registered' : 'Register'}
                            </button>
                        </div>

                    </div>
                </div>

                <div className="home__timeline">

                    <h2>TIMELINE & DATES</h2>

                    <div className="home__timeline__container">

                        <div className="home__timeline__item">
                            <div className="home__timeline__item__label">
                                <div className="home__timeline__item__label__circle">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="home__timeline__item__content">
                                <h3>Application Phase</h3>
                                <p className='home__timeline__item__content__date'>1 October 2024 to 1 April 2025, 23:59 (GMT+8)</p>
                                <p className='home__timeline__item__content__text'>
                                    Register on the platform and submit your presentation. Please keep your PDF presentations up to a maximum of 10 slides
                                </p>
                                <p className='home__timeline__item__content__text'>
                                    Download a sample of the <span onClick={() => window.open("https://firebasestorage.googleapis.com/v0/b/innovativeminds-7c1a4.appspot.com/o/Sample%20Pitch%20deck%20template%20.pdf?alt=media&token=dbc82476-f8bd-4528-a3c8-046e6db07246")}>presentation here</span>
                                </p>
                            </div>
                        </div>

                        <div className="home__timeline__item">
                            <div className="home__timeline__item__label">
                                <div className="home__timeline__item__label__circle">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="home__timeline__item__content">
                                <h3>Deepening Phase</h3>
                                <p className='home__timeline__item__content__date'>5 April to 10 April 2025, 23:59 (GMT+8)</p>
                                <p className='home__timeline__item__content__text'>
                                    Shortlisted  Startups will submit a 3-minute video introducing your business, elevator pitch style!

                                </p>
                                <p className='home__timeline__item__content__text'>
                                    After the submission, shortlisted applicants might be selected for a zoom conference video call with the
                                    organizers for any further questions about the application or any clarification needed
                                </p>
                            </div>
                        </div>

                        <div className="home__timeline__item">
                            <div className="home__timeline__item__label">
                                <div className="home__timeline__item__label__circle">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="home__timeline__item__content">
                                <h3>Grand Finale</h3>
                                <p className='home__timeline__item__content__date'>8 May to 10 May 2025</p>
                                <p className='home__timeline__item__content__text'>
                                    Top 10 Startups would be selected after the deepening pahse to battle out for the ulitmate grand prize
                                </p>
                                <p className='home__timeline__item__content__text'>
                                    Startups will have the opportunity to meet, interact with and pitch to industry leaders and global investors through your Afro Innovation journey.
                                    Thw award ceremony would take place with every participant receiving grants and gifts from sponsors
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="home__editions">

                    <h3>PREVIOUS EDITIONS</h3>

                    <div className="home__editions__container">

                        <div className="home__editions__item">

                            <div className="home__editions__item__img">
                                <img src={Year1} alt="Edition official poster" />
                            </div>
                            <p>2023 Afro Innovation Event</p>
                            <div className="home__editions__item__btnsection">
                                <button className='btn home__editions__item__btn'>Watch Highlights</button>
                            </div>
                        </div>

                        <div className="home__editions__item">

                            <div className="home__editions__item__img">
                                <img src={Year2} alt="Edition official poster" />
                            </div>
                            <p>2022 Afro Innovation Event</p>
                            <div className="home__editions__item__btnsection">
                                <button className='btn home__editions__item__btn'>Watch Highlights</button>
                            </div>
                        </div>

                        <div className="home__editions__item">

                            <div className="home__editions__item__img">
                                <img src={Year3} alt="Edition official poster" />
                            </div>
                            <p>2021 Afro Innovation Event</p>
                            <div className="home__editions__item__btnsection">
                                <button className='btn home__editions__item__btn'>Watch Highlights</button>
                            </div>
                        </div>

                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}

export default Home