import React, { useState, useContext, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { AppDataContext } from '../../Contexts/AppDataContext';
import Logo from '../../img/logo.png';
import { IoMdMail } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import { Header, Footer, Spinner } from '../../_shared'
import { useNavigate, useLocation } from 'react-router-dom';
import EmailDropdown from './EmailDropdown';
import { loginUser } from './dbFunctions';

const inlineStyle = {
    display: 'none',
    width: '0',
}

function Login() {

    const navigate = useNavigate()
    const location = useLocation()
    const [showDropdown, setShowDropdown] = useState(false)
    const { setUserData, setLoggedIn, setMessage } = useContext(AppDataContext)
    const [showSpinner, setShowSpinner] = useState(false)
    const [email, setEmail] = useState("")
    const [pword, setPword] = useState("")

    const receivedProp = location.state?.from;

    const notify = (message) => toast.success(message, { position: 'top-center' });

    const setError = (message) => toast.error(message, { position: 'top-center' })

    useEffect(() => {
        if (receivedProp === 'signup') {
            notify('Account Created. Now Login')
        }
    }, [])

    function handleLogin(e) {
        e.preventDefault()
        
        if (email === "" || pword === "") {
            setError("Please complete form")
        } else {
            setShowSpinner(true)
            loginUser(email, pword, setUserData, setShowSpinner, navigate, setError, setLoggedIn, setMessage)
        }
    }

    return (
        <>
            <Header />
            <div className='login'>
                <ToastContainer />

                <EmailDropdown
                    showModal={showDropdown}
                    hideModal={() => setShowDropdown(false)}
                    setSuccess={notify}
                    setError={setError}
                />
                <h3>LOGIN</h3>
                <Spinner
                    showSpinner={showSpinner}
                />

                <form action="" style={showSpinner ? inlineStyle : {}} onSubmit={handleLogin}>
                    <div className="login__form" >
                        <div className="login__form__inputbox">
                            <label htmlFor="email">Email</label>
                            <div className="login__form__input__wrap">
                                <div className="login__form__input__icon__box">
                                    <IoMdMail className="login__form__input__icon" />
                                </div>
                                <input
                                    type="email"
                                    className='login__form__input'
                                    name="email"
                                    id='email'
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="login__form__inputbox">
                            <label htmlFor="pword">Password</label>
                            <div className="login__form__input__wrap">
                                <div className="login__form__input__icon__box">
                                    <RiLockPasswordFill className="login__form__input__icon" />
                                </div>
                                <input
                                    type="password"
                                    className='login__form__input'
                                    name="pword"
                                    id="pword"
                                    value={pword}
                                    onChange={(e)=>setPword(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="login__actionbtns">
                        <div className="login__actionbtns__rmbme">
                            <input type="checkbox" id="rmbme" name="rmbme" />
                            <label htmlFor="rmbme">Remember me</label>
                        </div>

                        <div
                            className="login__actionbtns__fgt"
                            onClick={() => setShowDropdown(true)}
                        >
                            Forgotten Password?
                        </div>
                    </div>

                    <div className="login__btnsection">
                        <button
                            className='btn login__btn'
                            type='submit'
                            // onClick={() => handleLogin()}
                        >
                            LOGIN
                        </button>
                    </div>
                    <div
                        className="login__create"
                        onClick={() => navigate('/signup')}
                    >
                        Not Registered Yet? Create Account
                    </div>
                </form>


            </div>

            <Footer />
        </>
    )
}


export default Login