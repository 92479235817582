import React, { useState, useContext, useRef, useEffect } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { MdModeEditOutline } from "react-icons/md";
import User from '../../img/avatar.png'
import HomeHeader from './HomeHeader';
import { Footer, Spinner } from '../../_shared';
import Menu from './Menu';
import EditModal from './EditModal';
import _ from 'lodash'
import { updateUserProfile } from './dbFunctions';
import { ToastContainer, toast } from 'react-toastify';
import { COUNTRIES, INDUSTRIES } from '../../_shared/Constants'

function Profile() {

  const [showMenu, setShowMenu] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [showEdit, setShowEdit] = useState(false);
  const [editLabel, setEditLabel] = useState("")
  const [editVal, setEditVal] = useState("")
  const [editName, setEditName] = useState("")
  const [editType, setEditType] = useState("")
  const [editList, setEditList] = useState([])
  const [image, setImage] = useState()
  const [imagePreview, setImagePreview] = useState()
  const imageInputRef = useRef()
  const [showSpinner, setShowSpinner] = useState(false)
  const { userData, setUserData } = useContext(AppDataContext)

  const [currentUser, setCurrentUser] = useState({
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    startupName: userData.startupName,
    startupLocation: userData.startupLocation,
    userLocation: userData.userLocation,
    industry: userData.industry,
    website: userData.website,
    imgurl: userData.imgurl,
    pdfLink: userData.pdfLink,
    password: userData.password,
    id: userData.id,
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to reload the page? You may lose data.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const inlineStyle = {
    display: 'none',
    width: '0'
  }

  const inlineBorder = {
    borderRadius: '1rem',
  }

  function renderEditModal(label, value, name, type, list) {
    // console.log(value)
    setEditVal(value)
    setEditLabel(label)
    setEditName(name)
    setEditType(type ? type : "")
    setEditList(list ? list: [])

    setShowEdit(true)
  }

  const notify = (message) => toast.success(message, { position: 'top-center' });

  const setError = (message) => toast.error(message, { position: 'top-center' })

  function handleSaveChanges() {
    setShowSpinner(true)
    

    if (image || !(_.isEqual(currentUser, userData))) {
      updateUserProfile(currentUser, image, setShowSpinner, notify, setError, setUserData)
      // alert('changes made')
    } else {
      setShowSpinner(false)
      notify("No changes made")
      // alert("No changes made")
    }

  }


  return (
    <div className='profile'>
      <ToastContainer />
      <HomeHeader
        setShowMenu={setShowMenu}
        showMenu={showMenu}
        setShowChat={setShowChat}
        showChat={showChat}
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        userData={userData}
      />
      <Menu
        showMenu={showMenu}
        userData={userData}

      />
      <EditModal
        showModal={showEdit}
        hideModal={() => setShowEdit(false)}
        label={editLabel}
        value={editVal}
        name={editName}
        type={editType}
        list={editList}
        setData={setCurrentUser}
        data={currentUser}
      />
      <div className="profile__landing">

        <div className="profile__landing__user">
          <div className="profile__landing__user__avatar">
            <img src={userData.imgurl || User} alt="Current User of application" />
          </div>
          <div className="profile__landing__user__details">
            <p>{`${userData.firstName} ${userData.lastName}`}</p>
            <div className="profile__landing__user__details__startup">
              Start-up
            </div>
          </div>
        </div>

        <div className="profile__landing__about">

          <div className="profile__landing__about__item profile__landing__about__item--1">
            <p className="profile__landing__about__item__value">0</p>
            <p className="profile__landing__about__item__label">Challenges</p>
          </div>

          <div className="profile__landing__about__item">
            <p className="profile__landing__about__item__value">2024</p>
            <p className="profile__landing__about__item__label">Joined</p>
          </div>

        </div>
      </div>

      <Spinner
        height="50vh"
        showSpinner={showSpinner}
      />

      <div className="profile__main" style={showSpinner ? inlineStyle : {}}>
        <div className="profile__main__edit">

          <EditItem
            label="First Name"
            value={currentUser.firstName}
            setShowEdit={renderEditModal}
            name="firstName"
            
          />

          <EditItem
            label="Last Name"
            value={currentUser.lastName}
            setShowEdit={renderEditModal}
            name="lastName"
          />

          <EditItem
            label="Picture"
            value={currentUser.imgurl}
            image={image}
            setImage={setImage}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
            imageInputRef={imageInputRef}

          />

          <EditItem
            label="Country of Incorporation"
            value={currentUser.startupLocation}
            setShowEdit={renderEditModal}
            name="startupLocation"
            type="select"
            list={COUNTRIES}
            
          />

          <EditItem
            label="Legal Name of your Startup"
            value={currentUser.startupName}
            setShowEdit={renderEditModal}
            name="startupName"
          />

          <EditItem
            label="Place of Residence"
            value={currentUser.userLocation}
            setShowEdit={renderEditModal}
            name='userLocation'
            type="select"
            list={COUNTRIES}
          />

          <EditItem
            label="Your Company Website"
            value={currentUser.website}
            setShowEdit={renderEditModal}
            name="website"
          />

          <EditItem
            label="Industry"
            value={currentUser.industry}
            setShowEdit={renderEditModal}
            name="industry"
            type="select"
            list={INDUSTRIES}
          />

          <button
            className='btn profile__main__edit__btn'
            onClick={() => handleSaveChanges()}
          >
            Save Changes
          </button>

        </div>

        <div className="profile__main__loaded">
          <div className="profile__main__loaded__first">
            <p>
              {`Your profile is ${userData.imgurl ? '100' : '80'}% complete`}
            </p>
            <div className="profile__main__loaded__wrap">
              <div className="profile__main__loaded__wrap__filled"
                style={userData.imgurl ? inlineBorder : {}}
              />
              <div 
                className="profile__main__loaded__wrap__unfilled"
                style={userData.imgurl ? inlineStyle : {}}
              />
            </div>
          </div>
          <div className="profile__main__loaded__second">
            Save Changes before exiting to allow changes to be saved
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Profile

const EditItem = (props) => {

  function handleImageChange(e) {
    if (!(_.isEmpty(e.target.files))) {
      props.setImage(e.target.files[0])
      props.setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
    
  }

  return (

    props.label === "Picture" ? (
      <div className="profile__main__edit__item">
        <input
          type="file"
          accept=".png, .jpg"
          hidden
          style={{ width: 0, display: 'none' }}
          id="profilepic"
          required
          onChange={handleImageChange}
          ref={props.imageInputRef}
        />
        <label htmlFor="profilepic">
          <div className="profile__main__edit__item__label">
            <p>{props.label}</p>
            <MdModeEditOutline className='profile__main__edit__item__label__icon' />
            <span>Edit</span>
          </div>
        </label>
        <div className="profile__main__edit__item__value">
          <img src={props.imagePreview || props.value || User} alt='Current User of application' />
        </div>
      </div>
    ) : (
      <div className="profile__main__edit__item">
        <div className="profile__main__edit__item__label">
          <p>{props.label}</p>
          <MdModeEditOutline
            className='profile__main__edit__item__label__icon'
            onClick={() => props.setShowEdit(props.label, props.value, props.name, props.type, props.list)}
          />
          <span
            onClick={() => props.setShowEdit(props.label, props.value, props.name, props.type, props.list)}
          >
            Edit
          </span>
        </div>
        <div className="profile__main__edit__item__value">
          {props.value}
        </div>
      </div>
    )
  )
}