import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import User from '../../img/person.png'
import { IoSettings } from "react-icons/io5";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { AppDataContext } from '../../Contexts/AppDataContext';

const inlineStyles1 = {
    display: 'none',
    width: '0'
}

const inlineStyles2 = {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto'
}

function Menu(props) {

    const navigate = useNavigate()
    const { setLoggedIn } = useContext(AppDataContext)

    return (
        <div
            className='menu'
            style={props.showMenu ? inlineStyles2 : inlineStyles1}
        >

            <div
                className="menu__item"
                onClick={() => navigate('/profile')}
            >
                <img src={props.userData.imgurl || User} alt="Profile of Current User" />
                <div className="menu__item__details">
                    <h4>{`${props.userData.firstName} ${props.userData.lastName}`}</h4>
                    <p>Edit Your Profile</p>
                </div>
            </div>

            <div
                className="menu__item"
                onClick={() => navigate('/settings')}
            >
                <IoSettings className='menu__item__icon' />
                <div className="menu__item__details">
                    <p>Settings</p>
                </div>
            </div>

            <div
                className="menu__item"
                onClick={() => navigate('/')}
            >
                <RiLogoutCircleRFill className='menu__item__icon' />
                <div 
                    className="menu__item__details"
                    onClick={() => {
                        setLoggedIn(false)
                        navigate('/')
                    }}
                >
                    <p>Logout</p>
                </div>
            </div>
        </div>
    )
}

export default Menu