import React from 'react'
import Rodal from 'rodal'
import Email from '../../img/email.png'
import Landline from '../../img/landline.png';
import Phone from '../../img/phone.png'
import { isMobile } from 'react-device-detect';

function Help(props) {
    return (

        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.82 : 800}
            height={isMobile ? 540 : 670}
        >
            <div className='help'>
                <h3>NEED HELP</h3>
                <h4>For immediate assistance, reach out to us via email or by phone at on weekdays.
                    We strive to provide prompt responses to all inquiries.</h4>
                <div className="help__container">
                    <div className="help__item">
                        <img src={Landline} alt="Landline" />
                        <div className="help__item__content">
                            <h5>Landline</h5>
                            <p>030 987 1298</p>
                        </div>
                        <span>Only available on Weekdays 8AM - 5PM</span>
                    </div>

                    <div className="help__item">
                        <img src={Phone} alt="Landline" />
                        <div className="help__item__content">
                            <h5>Phone</h5>
                            <p>025 526 9228</p>
                        </div>
                        <span>Only available on Weekdays 8AM - 5PM</span>
                    </div>

                    <div className="help__item">
                        <img src={Email} alt="Landline" />
                        <div className="help__item__content">
                            <h5>Email</h5>
                            <p>support@afroinnovationsummit.com</p>
                        </div>
                        <span>Available all days 6AM - 6PM</span>
                    </div>
                </div>

                <div className="help__btnsection">
                    <button className="btn help__btn">Close</button>
                </div>
            </div>
        </Rodal>
    )
}

export default Help