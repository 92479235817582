import React, { useState } from 'react'
import { GiTrophy, GiLaurelsTrophy } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";

function Prizes() {

  const [selectedLabel, setSelectedLabel] = useState(0)

  function setPrize() {

    switch (selectedLabel) {
      case 0:
        return <Winner />

      case 1:
        return <Top10 />

      case 2:
        return <Top50 />

      default:
        break;
    }
  }

  return (
    <div className='prizes'>

      <h3>PRIZES</h3>

      <div className="prizes__container">

        <div className="prizes__label" data-aos="fade-right" data-aos-duration="1500">

          <div 
            className={`prizes__label__item ${selectedLabel === 0 ? 'prizes__label__item__selected' : ''}`}
            onClick={() => setSelectedLabel(0)}
          >
            <div className="prizes__label__item__icon__box">
              <GiTrophy className='prizes__label__item__icon' />
              <p>3</p>
            </div>
            <div className="prizes__label__item__icon__text">Top 3 Winners</div>
          </div>


          <div 
            className={`prizes__label__item ${selectedLabel === 1 ? 'prizes__label__item__selected' : ''}`}
            onClick={() => setSelectedLabel(1)}
          >
            <div className="prizes__label__item__icon__box">
              <GiLaurelsTrophy className='prizes__label__item__icon' />
              <p>10</p>
            </div>
            <div className="prizes__label__item__icon__text">Top 10 Startups</div>
          </div>


          <div 
            className={`prizes__label__item ${selectedLabel === 2 ? 'prizes__label__item__selected' : ''}`}
            onClick={() => setSelectedLabel(2)}
          >
            <div className="prizes__label__item__icon__box">
              <IoIosPeople className='prizes__label__item__icon' />
              <p>50</p>
            </div>
            <div className="prizes__label__item__icon__text">All Participants</div>
          </div>

        </div>

        <div className="prizes__main" data-aos="fade-up" data-aos-duration="1500">
          <div className="prizes__main__box">
            <p className="prizes__main__prize">
              
              {
                selectedLabel ? selectedLabel === 1 ? `₵2,000` : 'Hampers' : '₵40,000'
              }
            </p>
            <p className="prizes__main__title">Startup Grant</p>
          </div>
        </div>

        <div className="prizes__details" data-aos="fade-left" data-aos-duration="1500">
          <div className="prizes__details__box">
            {setPrize()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prizes

const Winner = () => {
  return (
    <>
      <div className="prizes__details__item">
        <h4>The Grand Winner of AFRO INNOVATION 2025 will receive:</h4>
        <p>Ghc50,000 Startup SG Grant Prize</p>
        <p>Mentorship and offers from sponsors</p>
      </div>

      <div className="prizes__details__item">
        <h4>The 1st Runner-Up of AFRO INNOVATION 2025 will receive:</h4>
        <p>Ghc25,000 Startup SG Grant Prize</p>
        <p>Mentorship and offers from sponsors</p>
      </div>

      <div className="prizes__details__item">
        <h4>The 2nd Runner-Up of AFRO INNOVATION 2025 will receive:</h4>
        <p>Ghc10,000 Startup SG Grant Prize</p>
        <p>Mentorship and offers from sponsors</p>
      </div>
    </>
  )
}

const Top10 = () => {
  return (
    <>
      <div className="prizes__details__item">
        <h4>The Top 10 Startups of AFRO INNOVATION 2025 will receive:</h4>
        <p>Ghc1,000 Startup SG Grant Prize</p>
        <p>Free career coaching and sessions with judges</p>
      </div>
    </>
  )
}

const Top50 = () => {
  return (
    <>
      <div className="prizes__details__item">
        <h4>All Startups of AFRO INNOVATION 2025 will receive:</h4>
        <p>Prizes from our sponsors</p>
        <p>Time on our channel to showcase their ideas</p>
      </div>
    </>
  )
}