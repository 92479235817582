const COUNTRIES = [
    "Please Select Country",
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", 
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", 
    "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", 
    "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", 
    "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", 
    "Colombia", "Comoros", "Congo (Congo-Brazzaville)", "Costa Rica", "Croatia", "Cuba", "Cyprus", 
    "Czechia (Czech Republic)", "Democratic Republic of the Congo", "Denmark", "Djibouti", 
    "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", 
    "Eritrea", "Estonia", "Eswatini (fmr. Swaziland)", "Ethiopia", "Fiji", "Finland", "France", 
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", 
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", 
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", 
    "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", 
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", 
    "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", 
    "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", 
    "Myanmar (formerly Burma)", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", 
    "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", 
    "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", 
    "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", 
    "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", 
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", 
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", 
    "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", 
    "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", 
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
    "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", 
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];

const DOMAINSLIST = [
    {
        title: 'Transformative Digital Technologies',
        imgClass: 'landing__domains__card__background--1',
        intro: 'Africa is experiencing a digital revolution, with internet penetration rates steadily increasing across the continent. While exact figures vary, it\'s estimated that hundreds of millions of Africans are now online, with millions more joining the digital world each year. The COVID-19 pandemic accelerated this trend, as more people turned to the internet for education, healthcare, and economic opportunities.',
        listhead: 'AFRO INNOVATION seeks promising startups that can ride the digital wave in Ghana and Africa. This includes areas such as:',
        list: ['Artificial Intelligence / Machine Learning', 'Augmented Reality / Virtual Reality', 'Big Data', 'Blockchain', 'Privacy & Security', '5G'],
        color: '#ff355e',
    },
    {
        title: 'Health and Biomedical Technologies',
        imgClass: 'landing__domains__card__background--2',
        intro: 'Cutting-edge technology in the sectors of health technology (healthtech), biotechnology (biotech) and medical technology (medtech) continue to be in demand. With significant advances in healthcare technology, new methods are necessary to support the high demand for access to healthcare and the growing digitalisation of protected health information. The healthcare industry has evolved in innovative and novel ways to continue to deliver quality services.',
        listhead: 'Join us if you have disruptive solutions in the following areas:',
        list: ['Biotech', 'Digital Health', 'Medtech'],
        color: '#00b7eb',
    },
    {
        title: 'Manufacturing, Trade and Connectivity',
        imgClass: 'landing__domains__card__background--3',
        intro: 'Manufacturers face changes on multiple fronts. One such change is the move towards advanced manufacturing — the increased adoption of innovative technologies such as advanced materials and robotics. At the same time, increased connectivity and ever more sophisticated data-gathering enabled by the Internet of Things (IoT) have led to a shift toward an information-based economy. The adoption of connectivity technologies and analytics by manufacturers have grown significantly within the past five years.',
        listhead: 'Innovative startups in the following areas are welcome to participate:',
        list: ['Advanced Manufacturing', 'IoT and Sensors', 'Mobility, Transportation and Logistics', 'Robotics and Automation'],
        color: '#bf00ff',
    },
    {
        title: 'Environment, Energy and Green Technologies',
        imgClass: 'landing__domains__card__background--4',
        intro: 'Digital technologies are playing a crucial role in driving Africa\'s transition to greener value chains. As the continent\'s economies continue to grow and develop, there is a rising demand for sustainable energy solutions. Governments and industry leaders across Africa are increasingly recognizing the importance of adopting eco-friendly practices and investing in renewable energy sources. This shift towards sustainability is expected to create significant economic opportunities, with the African green energy market projected to reach substantial value in the coming years.',
        listhead: 'If you have a game changing innovation that tackles the following, this category is for you.  ',
        list: ['Agriculture Technology', 'Clean Energy', 'Renewable and Alternative energy', 'Waste Management and Treatment Solutions', 'Water and Environment'],
        color: '#008000',
    },
    {
        title: 'Consumer Media, Goods and Services',
        imgClass: 'landing__domains__card__background--5',
        intro: 'The line between creators and consumers is becoming increasingly blurred as the barriers between industries, brands, and users dissolve. Consumer technology and media services have unlocked the need to provide customers with a more authentic and personalised online experience. As one of the key disruptors of the digital landscape, this domain has the potential to fundamentally shift the relationship between businesses and their audience.',
        listhead: 'We are looking for innovative solutions in the following areas:',
        list:['Advertising Technology, Content and Marketing', 'Education Technology', 'Financial Technology (Insurtech and P2P Lending)', 'Food Technology', 'Retail Technology (Experiential Retailing)', 'Product Personalisation Technologies'],
        color: '#ff8c00',
    }
]

const INDUSTRIES = ['Please Select Industry', 'Mobile', 'IOT', 'Digital Media', 'Cybersecurity', 'IT', 'Big Data Analytics,Cloud',
    'E-commerce', 'Fraud Prevention', 'Authentication', 'Geo-Tracking', 'Fintech', 'Gaming', 'Internet',
    'E-Health', 'Business Support', 'Biotech', 'Big Data', 'OCR', 'Consumer Technologies',
    'Transportation & Logistics', 'Sustainability', 'Blockchain', '5G', 'Urban Technologies',
    'Foodtech & Agritech'
  ]

export { COUNTRIES, DOMAINSLIST, INDUSTRIES }
