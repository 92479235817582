import React, { Component } from 'react'
import { SyncLoader } from 'react-spinners';
import { isMobile } from 'react-device-detect'

function Spinner(props) {

  const inlineStyle = {
    height: props.height
  }

  const inlineStyle2 = {
    height: '0'
  }

  return (
    <div className="spinner" style={props.showSpinner ? inlineStyle : inlineStyle2}>
      <SyncLoader color='#333399' loading={props.showSpinner} size={isMobile ? 60 : 90} margin={10} />
    </div>
  )
}


export default Spinner