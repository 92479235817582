import React from 'react'
import Rodal from 'rodal';
import { isMobile } from 'react-device-detect';

function DomainDropdown(props) {
    //console.log(props.domain)


    const inlineStyles = {
        backgroundColor: props.domain.color,
    }

    return (
        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.86 : 850}
            height={620}
        >
            <div className='domaindd'>
                <div
                    className="domaindd__header"
                    style={inlineStyles}
                >
                    {props.domain.title}
                </div>
                <div className="domaindd__container">
                    <div className="domaindd__intro">{props.domain.intro}</div>
                    <p>{props.domain.listhead}</p>
                    <ul>
                        {
                            props.domain.list.map((item) => <li>{item}</li>)
                        }
                    </ul>
                    <div className="domaindd__btnsection">
                        <button
                            className="btn domaindd__btn"
                            style={inlineStyles}
                            onClick={() => props.hideModal()}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Rodal>
    )
}

export default DomainDropdown