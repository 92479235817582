import React, { useEffect, useState } from 'react'
import { RiLockPasswordFill } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';
import { Header, Footer, Spinner } from '../../_shared'
import { useParams } from 'react-router-dom';
import { FaLock } from 'react-icons/fa';
import { updatePasswordById } from './dbFunctions';

const inlineStyle = {
  display: 'none',
  width: '0',
}

function ResetPassword() {

  const { token } = useParams();
  const [showSpinner, setShowSpinner] = useState(true)
  const [id, setId] = useState("")
  const [pword, setPword] = useState('')
  const [retypePword, setRetypePword] = useState('')

  const notify = (message) => toast.success(message, { position: 'top-center' });

  const setError = (message) => toast.error(message, { position: 'top-center' })

  useEffect(() => {
    const user = getFirstAtIndex(token)
    //console.logog(user)
    setId(user)
    setShowSpinner(false)
  }, [])

  function getFirstAtIndex(str) {
    const index = str.indexOf('@');
    if (index === -1) {
      return str; // If no '@' is found, return the entire string
    }
    return str.substring(0, index);
  }

  function handlePasswordChange() {
    setShowSpinner(true)
    if (pword === retypePword) {
      updatePasswordById(id, pword, notify, setError, setShowSpinner)
    } else {
      setShowSpinner(false)
      setError("Passwords don't match")
    }
  }


  return (
    <>
      <Header />
      <ToastContainer />
      <Spinner showSpinner={showSpinner} />
      <div
        className="login"
        style={showSpinner ? inlineStyle : {}}
      >

        <h3>PASSWORD RESET</h3>

        <div className="login__form">
          <div className="login__form__inputbox">
            <label htmlFor="mail">New Psssword</label>
            <div className="login__form__input__wrap">
              <div className="login__form__input__icon__box">
                <RiLockPasswordFill className="login__form__input__icon" />
              </div>
              <input
                type="password"
                className='login__form__input'
                name="pword"
                id="pword"
                value={pword}
                onChange={(e) => setPword(e.target.value)}
              />
            </div>
          </div>

          <div className="login__form__inputbox">
            <label htmlFor="retypepword">Confirm Password</label>
            <div className="login__form__input__wrap">
              <div className="login__form__input__icon__box">
                <FaLock className="login__form__input__icon" />
              </div>
              <input
                type="password"
                className='login__form__input'
                name="retypepword"
                id="retypepword"
                value={retypePword}
                onChange={(e) => setRetypePword(e.target.value)}
              />
            </div>
          </div>

        </div>

        <div
          className="login__btnsection"
          onClick={() => handlePasswordChange()}
        >
          <button className='btn login__btn'>RESET</button>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default ResetPassword