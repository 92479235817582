import React, { useRef, useState } from 'react'
import Rodal from 'rodal'
import File from '../../img/file.png'
import Nofile from '../../img/no-file.png';
import { uploadPDFtoFirebaseStorage } from './dbFunctions';
import { Spinner } from '../../_shared';
import { isMobile } from 'react-device-detect';


function RegisterDropdown(props) {

    const [pdfFile, setPdfFile] = useState()
    const [showSpinner, setShowSpinner] = useState(false)
    const fileRef = useRef()

    function handleFileChange(e) {
        setPdfFile(e.target.files[0])
    }

    function handleSubmit() {
        if (pdfFile) {
            setShowSpinner(true)
            uploadPDFtoFirebaseStorage(pdfFile, props.userData, setShowSpinner, props.setSuccess, props.setError, props.hideModal, props.setUserData)
        } else {
            alert("Upload a file first")
        }
    }

    const inlineStyle= {
        display: 'none',
        width: '0'
    }

    return (
        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth* 0.82 :800}
            height={isMobile ? 550 : 650}
        >
            <div className='registerdd'>
                <h3>REGISTER FOR EVENT</h3>


                <Spinner
                    showSpinner={showSpinner}
                    height="600px"
                />


                <div className="registerdd__container" style={showSpinner ? inlineStyle : {}}>
                    <p
                        onClick={() => window.open("https://firebasestorage.googleapis.com/v0/b/innovativeminds-7c1a4.appspot.com/o/Sample%20Pitch%20deck%20template%20.pdf?alt=media&token=dbc82476-f8bd-4528-a3c8-046e6db07246")}
                    >
                        Please click here to download a sample PDF presentation to help you and
                        guide you on how to present your solution if you do not already have it
                    </p>

                    <h4>Upload Presentation</h4>

                    <div className="registerdd__upload">
                        <div className="registerdd__upload__preview">
                            <input
                                type="file"
                                accept=".pdf"
                                hidden
                                style={{ width: 0, display: 'none' }}
                                id="pdf"
                                required
                                onChange={handleFileChange}
                                ref={fileRef}
                            />
                            <label htmlFor="pdf">
                                <div className="registerdd__upload__preview__imgbox">
                                    <img src={pdfFile ? File : Nofile} alt="Preview of uploaded file" />
                                </div>
                                <p>{pdfFile ? pdfFile.name.slice(0, 15) : 'Click image to upload'}</p>
                            </label>
                        </div>
                    </div>

                    <div className="registerdd__btnsection">
                        <button
                            className="btn registerdd__btn"
                            onClick={() => handleSubmit()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </Rodal>

    )
}

export default RegisterDropdown