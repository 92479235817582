import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Landing } from "./features/Landing";
import { Signup } from "./features/Signup";
import { Login, ResetPassword } from "./features/Login";
import { Home, Settings, Profile } from "./features/Home";
import { ProtectedRoute } from "./_shared";

function Navigator() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}

      <Routes>
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Landing />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/home" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Navigator;
