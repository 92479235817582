import { collection, getDocs, query, where, getFirestore, updateDoc, doc } from "firebase/firestore";
import _ from 'lodash'
import emailjs from "@emailjs/browser";

async function loginUser(email, password, setUserData, setShowSpinner, navigate, notify, setLoggedIn, setMessage) {
    let result = []
    let message = []
    try {
        const db = getFirestore()
        const usersRef = collection(db, "users")
        const q1 = query(usersRef, where("email", "==", email), where("password", "==", password));
        const querySnapshot = await getDocs(q1);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            result.push({ id: doc.id, ...doc.data() })
        });

        if (_.isEmpty(result)) {
            notify("Email or Password Wrong")
            setShowSpinner(false)
        } else {

            const querySnapshot2 = await getDocs(collection(getFirestore(), 'messages'));
            querySnapshot2.forEach((doc) => {
                message.push(doc.data())
                //console.log(doc.data())
            });


            setShowSpinner(false)
            setLoggedIn(true)
            setUserData(result[0])
            setMessage(message[0])
            navigate('/home')
        }
    } catch (error) {
        setShowSpinner(false)
        notify('Error Loggin in. Try again later')
        //console.log(error)
        throw error
    }

}

async function updatePasswordById(documentId, newPassword, setSuccess, setError, setShowSpinner) {
    const db = getFirestore();

  try {
    const userRef = doc(db, "users", documentId);
    await updateDoc(userRef, {
      password: newPassword,
    });
    setShowSpinner(false)
      setSuccess('Password updated successfully');
      // console.log('Password updated successfully.');
    } catch (error) {
        setShowSpinner(false)
        setError('Error updating password')
      // console.error('Error updating password:', error);
    }
  }

async function sendForgotMail(email, setSuccess, setError, formRef, setLoading) {
    const db = getFirestore()
    try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('email', '==', email));
        const querySnapshot = await getDocs(q);
        // const querySnapshot = await db.collection('users').where('email', '==', email).get();

        if (querySnapshot.empty) {
            // No user found with the given email
            setLoading(false)
            setError('Email not found')
            return null;

        } else {
            const userDoc = querySnapshot.docs[0];
            const userID = userDoc.id;

            const randomString = generateRandomCharacters(20)
            const hashLink = 'https://www.afroinnovationsummit.com/resetpassword/' + userID + '@' + randomString;

            const linkInput = document.createElement('input');
            linkInput.setAttribute('type', 'hidden');
            linkInput.setAttribute('name', 'link');
            linkInput.setAttribute('value', hashLink);

            formRef.appendChild(linkInput)

            sendEmail(setSuccess, setError, formRef, setLoading)
        }






    } catch (error) {
        console.error('Error finding user ID:', error);
        return null;
    }
}


const sendEmail = (setSuccess, setError, formRef, setLoading) => {
    emailjs
        .sendForm(
            "service_xazlyod", // Replace with your service ID
            "template_fxoj6an", // Replace with your template ID
            formRef,
            "HTl7DyvUw58VKUv0b" // Replace with your public key
        )
        .then((response) => {
            setLoading(false)
            setSuccess("Password reset email sent")
            //console.log("Email sent successfully:", response.status);
        })
        .catch((error) => {
            setLoading(false)
            setError("Problem Creating Account. Contact Help Email");
            console.error("Failed to send email:", error);
        });
};


function generateRandomCharacters(length) {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;

}

export { loginUser, sendForgotMail, updatePasswordById }