import React from 'react'

function WhyJoin() {
    return (
        <div className='landing__whyjoin'>

            <h3>WHY JOIN</h3>

            <div className="landing__whyjoin__container">

                <div className="landing__whyjoin__item" data-aos="flip-left" data-aos-duration="1500">

                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--1">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--1">Build Valuable Network</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Other Participants</li>
                                    <li>Investors</li>
                                    <li>Hiring Companies</li>
                                </ul>
                            </div>
                        </div>


                        <div class="card__side card__side--back card__side--back-1">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Details</p>
                                    <p class="card__price-value">
                                        Expand your professional network and connect with industry leaders at our competition
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="landing__whyjoin__item" data-aos="flip-up" data-aos-duration="1500">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--2">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--2">Showcase brilliant ideas</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Gain global recognition</li>
                                    <li>Attract potential investors</li>
                                    <li>Receive expert feedback</li>
                                </ul>
                            </div>

                        </div>
                        <div class="card__side card__side--back card__side--back-2">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Details</p>
                                    <p class="card__price-value">
                                        Unleash your creativity and present your innovative solutions to a global audience
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="landing__whyjoin__item" data-aos="flip-right" data-aos-duration="1500">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--3">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--3">Accelerate your business</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Secure essential funding</li>
                                    <li>Receive expert mentorship</li>
                                    <li>Access valuable resources</li>
                                </ul>
                            </div>

                        </div>
                        <div class="card__side card__side--back card__side--back-3">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Details</p>
                                    <p class="card__price-value">
                                        Empower your startup with the support and resources needed to succeed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyJoin