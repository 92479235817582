import React, { useState, useRef } from 'react'
import { Header, Footer } from '../../_shared';
import { IoMdMail } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import { MdPerson3 } from "react-icons/md";
import { FaLock, FaIndustry, FaGlobeAfrica, FaGlobe, FaRobot } from "react-icons/fa";
import { DiWebplatform } from "react-icons/di";
import { SiProbot } from "react-icons/si";
import { COUNTRIES, INDUSTRIES } from '../../_shared/Constants'
import { useNavigate } from 'react-router-dom';
import { createUser } from './dbFunctions';
import { Spinner } from '../../_shared';
import { ToastContainer, toast } from 'react-toastify';
import Terms from './Terms'
import Privacy from './Privacy'

const fadePage = {
  display: 'none',
  width: '0',
}

function Signup() {

  const [page, setPage] = useState(0)
  const [showTerms, setShowTerms] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const navigate = useNavigate()
  const formRef = useRef();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [terms, setTerms] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [startupName, setStartupName] = useState('');
  const [startupLocation, setStartupLocation] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [industry, setIndustry] = useState('');
  const [website, setWebsite] = useState('');

  const [showSpinner, setShowSpinner] = useState(false);

  const inlineStyle = {
    display: 'none',
    width: '0',
  }

  function handleCreateAccount(e) {
    e.preventDefault()

    setShowSpinner(true)
    if (startupName === "" || startupLocation === "" || userLocation === "" || industry === "" ||
      firstName === '' || lastName === "" || email === "" || password === "" || !terms || !policy
    ) {
      setShowSpinner(false)
      notify("Please complete the form")
    } else {

      if (password.length > 8) {
        const userData = {
          firstName,
          lastName,
          email,
          startupName,
          startupLocation,
          userLocation,
          industry,
          website,
          password,
        }

        createUser(navigate, userData, notify, setShowSpinner, document.getElementById('myform'))
      } else {
        setShowSpinner(false)
        notify("Password too weak")
      }


    }

  }

  const notify = (message) => toast.error(message, { position: 'top-center' });

  return (
    <>
      <Header />
      <div className='signup'>
        <ToastContainer />

        <h3>CREATE ACCOUNT</h3>

        <Spinner showSpinner={showSpinner} />
        <Terms 
          showModal={showTerms}
          hideModal={() => setShowTerms(false)}
        />
        <Privacy
          showModal={showPrivacy}
          hideModal={() => setShowPrivacy(false)}
        />
        <form action="" ref={formRef} onSubmit={handleCreateAccount} id="myform">
          <div className="signup__form" style={showSpinner ? inlineStyle : {}}>

            {
              <>

                <BasicInfo
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}

                />

                <StartupInfo
                  startupName={startupName}
                  setStartupName={setStartupName}
                  startupLocation={startupLocation}
                  setStartupLocation={setStartupLocation}
                  industry={industry}
                  setIndustry={setIndustry}
                  userLocation={userLocation}
                  setUserLocation={setUserLocation}
                  website={website}
                  setWebsite={setWebsite}
                />

                <Agreement
                  terms={terms}
                  setTerms={setTerms}
                  policy={policy}
                  setPolicy={setPolicy}
                  setShowTerms={setShowTerms}
                  setShowPrivacy={setShowPrivacy}
                />
              </>
            }

          </div>


          <div className="login__btnsection">
            <button
              className='btn login__btn'
              type='submit'
            //onClick={(e) => handleCreateAccount(e)}
            >
              Submit
            </button>
          </div>
          <div
            className="login__create"
            onClick={() => navigate('/login')}
          >
            Have An Account? Login
          </div>

        </form>

      </div>
      <Footer />
    </>
  )
}

export default Signup;

const BasicInfo = (props) => {
  return (
    <>
      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="firstName">First Name</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <IoPerson className="login__form__input__icon" />
          </div>
          <input
            type="text"
            className='login__form__input'
            name="firstName"
            id='firstName'
            placeholder='eg. Johnson'
            value={props.firstName}
            onChange={(e) => props.setFirstName(e.target.value)}
          />
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="lastName">Last Name</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <MdPerson3 className="login__form__input__icon" />
          </div>
          <input
            type="text"
            className='login__form__input'
            name="lastName"
            id='lastName'
            placeholder='eg. Aikings'
            value={props.lastName}
            onChange={(e) => props.setLastName(e.target.value)}
          />
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="email">Email</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <IoMdMail className="login__form__input__icon" />
          </div>
          <input
            type="email"
            className='login__form__input'
            name="email"
            id='email'
            placeholder='eg. johnsonaikins@gmail.com'
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="pword">Password</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <FaLock className="login__form__input__icon" />
          </div>
          <input
            type="password"
            className='login__form__input'
            name="pword"
            id='pword'
            placeholder='eg. StrongPassword@$%109'
            value={props.password}
            onChange={(e) => props.setPassword(e.target.value)}
          />
        </div>
      </div>


    </>
  )

}

const StartupInfo = (props) => {
  return (
    <>
      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="startupname">Legal Name of your Startup</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <FaRobot className="login__form__input__icon" />
          </div>
          <input
            type="text"
            className='login__form__input'
            name="startupname"
            id='startupname'
            placeholder='eg. CreoTech Systems'
            value={props.startupName}
            onChange={(e) => props.setStartupName(e.target.value)}
          />
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="industry">Industry</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <FaIndustry className="login__form__input__icon" />
          </div>
          <select
            type="text"
            className='login__form__input'
            name="industry"
            id='industry'
            value={props.industry}
            onChange={(e) => props.setIndustry(e.target.value)}
          >
            {
              INDUSTRIES.map((item, index) => {
                return (
                  <option
                    value={item}
                    hidden={!index}
                  >
                    {item}
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <div className="login__form__input__label">
          <label htmlFor="description">Country of Incorporation</label>
          <p><span>Description: </span>In which country is your Startup HQ registered?</p>
        </div>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <FaGlobeAfrica className="login__form__input__icon" />
          </div>
          <select
            type="text"
            className='login__form__input'
            name="description"
            id='description'
            value={props.startupLocation}
            onChange={(e) => props.setStartupLocation(e.target.value)}
          >
            {
              COUNTRIES.map((item, index) => {
                return (
                  <option
                    value={item}
                    hidden={!index}
                  >
                    {item}
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <label htmlFor="residence">Place of Residence</label>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <FaGlobe className="login__form__input__icon" />
          </div>
          <select
            type="text"
            className='login__form__input'
            name="residence"
            id='residence'
            value={props.userLocation}
            onChange={(e) => props.setUserLocation(e.target.value)}
          >
            {
              COUNTRIES.map((item, index) => {
                return (
                  <option
                    value={item}
                    hidden={!index}
                  >
                    {item}
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">
        <div className="login__form__input__label">
          <label htmlFor="website">Your Company Website (Optional) </label>
          <p><span>Note: </span>Make sure to add https:// at the beginning of the URL!</p>
        </div>
        <div className="login__form__input__wrap">
          <div className="login__form__input__icon__box">
            <DiWebplatform className="login__form__input__icon" />
          </div>
          <input
            type="text"
            className='login__form__input'
            name="website"
            id='website'
            placeholder='eg. https://creotechsys.com'
            value={props.website}
            onChange={(e) => props.setWebsite(e.target.value)}
          />
        </div>
      </div>

    </>
  )
}

const Agreement = (props) => {
  return (
    <>

      <div className="login__form__inputbox" data-aos="fade-up">

        <div className="login__form__checkbox__wrap">

          <input
            type="checkbox"
            className='login__form__checkbox'
            name="service"
            id='service'
            value={props.terms}
            onChange={() => props.setTerms(!props.terms)}
          />
          <label htmlFor="service">
            I accept the <span onClick={() => props.setShowTerms(true)}>Terms of service</span>
          </label>
        </div>
      </div>

      <div className="login__form__inputbox" data-aos="fade-up">

        <div className="login__form__checkbox__wrap">

          <input
            type="checkbox"
            className='login__form__checkbox'
            name="policy"
            id='policy'
            value={props.policy}
            onChange={() => props.setPolicy(!props.policy)}
          />
          <label htmlFor="policy">
            I accept the <span onClick={() => props.setShowPrivacy(true)}>Privacy policy</span>
          </label>
        </div>
      </div>

    </>
  )
}
