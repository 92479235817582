import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import Rodal from 'rodal'

function EditModal(props) {

    const [inputVal, setInputVal] = useState("")
    // console.log(inputVal)
    return (

        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.8 : 500}
            height={isMobile? 350 : 400}
        >
            <div className='editdd'>
                <h3>EDIT YOU PROFILE</h3>

                <div className="editdd__input">
                    <label htmlFor={props.label}>{props.label}</label>
                    {
                        props.type ?
                            <select
                                type="text"
                                name={props.name}
                                id={props.name}
                                value={inputVal}
                                onChange={(e) => setInputVal(e.target.value)}
                            >
                                {
                                    props.list.map((item, index) => {
                                        return (
                                            <option
                                                value={item}
                                                hidden={!index}
                                            >
                                                {item}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            :
                            <input
                                type="text"
                                name={props.name}
                                id={props.name}
                                value={inputVal}
                                onChange={(e) => setInputVal(e.target.value)}
                                placeholder={props.value}
                            />
                    }
                </div>

                <button
                    className="btn editdd__btn"
                    onClick={() => {
                        props.setData({ ...props.data, [props.name]: inputVal })
                        setInputVal("")
                        props.hideModal()
                    }}
                >
                    Set & Close
                </button>
            </div>
        </Rodal>
    )
}

export default EditModal