import React from 'react'
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { GiVideoConference, GiModernCity, GiTrophyCup } from "react-icons/gi";

function Timeline() {
    return (
        <div className='landing__timeline'>
            <h3>Key Dates & Timeline</h3>

            <div className="landing__timeline__box">

                <div className="landing__timeline__item" data-aos="fade-up" data-aos-duration="1500">
                    <div className="landing__timeline__item__head">
                        <div className="landing__timeline__item__head__icon__box">
                            <HiOutlineSpeakerphone className='landing__timeline__item__head__icon' />
                        </div>
                        <div className="landing__timeline__item__head__title">
                            <p className="landing__timeline__item__head__title__name">CALL FOR APPLICATION</p>
                            <p className="landing__timeline__item__head__title__date">1 Oct - 1 Apr</p>
                        </div>
                    </div>
                    <div className="landing__timeline__item__text">
                        Registration is now open! Submit your application and showcase your innovative solution.
                        Upon successful registration, you will receive an official confirmation email.
                        This email will be used to keep you informed about your application status
                        and any upcoming stages of the competition.
                    </div>
                </div>

                <div className="landing__timeline__item" data-aos="fade-up" data-aos-duration="1500">
                    <div className="landing__timeline__item__head">
                        <div className="landing__timeline__item__head__icon__box">
                            <GiVideoConference className='landing__timeline__item__head__icon' />
                        </div>
                        <div className="landing__timeline__item__head__title">
                            <p className="landing__timeline__item__head__title__name">VIRTUAL CONFERENCE</p>
                            <p className="landing__timeline__item__head__title__date">5 Apr - 10 Apr</p>
                        </div>
                    </div>
                    <div className="landing__timeline__item__text">
                        The virtual conference to screen qualified applicants will be held on Zoom.
                        Specific dates and times will be communicated to those who advance to the next round.
                        Please ensure you are available during the scheduled times or contact us via email to request a change.
                    </div>
                </div>

                <div className="landing__timeline__item" data-aos="fade-up" data-aos-duration="1500">
                    <div className="landing__timeline__item__head">
                        <div className="landing__timeline__item__head__icon__box">
                            <GiModernCity className='landing__timeline__item__head__icon' />
                        </div>
                        <div className="landing__timeline__item__head__title">
                            <p className="landing__timeline__item__head__title__name">THE ACCRA IMMERSION</p>
                            <p className="landing__timeline__item__head__title__date">6 May - 8 May</p>
                        </div>
                    </div>
                    <div className="landing__timeline__item__text">
                        The top 50 participants will be invited to Accra to meet with the organizers, local sponsors, and industry experts.
                        Before the final event, participants will receive training and mentorship to
                        help them refine their pitches and prepare for the grand finale.
                    </div>
                </div>

                <div className="landing__timeline__item" data-aos="fade-up" data-aos-duration="1500">
                    <div className="landing__timeline__item__head">
                        <div className="landing__timeline__item__head__icon__box">
                            <GiTrophyCup className='landing__timeline__item__head__icon' />
                        </div>
                        <div className="landing__timeline__item__head__title">
                            <p className="landing__timeline__item__head__title__name">THE GRAND FINALE</p>
                            <p className="landing__timeline__item__head__title__date">9 May - 10 May</p>
                        </div>
                    </div>
                    <div className="landing__timeline__item__text">
                        The Grand Finale will be a thrilling event where the top contestants will compete for the ultimate prize.
                        The finalists will deliver their pitches to a live audience and a panel of esteemed judges.
                        Following the competition, an award ceremony will be held to announce the winner
                        and celebrate the achievements of all participants.
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Timeline