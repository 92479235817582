import { doc, getFirestore, addDoc, collection, query, where, getDocs } from "firebase/firestore";
import emailjs from "@emailjs/browser";

async function createUser(navigate, userData, notify, setShowSpinner, formRef) {
  const db = getFirestore();

  const emailExists = await checkEmailExists(userData.email)

  if (!emailExists) {

    try {
      const data = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        startupName: userData.startupName,
        startupLocation: userData.startupLocation,
        userLocation: userData.userLocation,
        industry: userData.industry,
        website: userData.website,
        password: userData.password,
        imgurl: "",
        pdfLink:"",
        joined: new Date()
      };
  
      // Store user details in Firestore
      const userRef = await addDoc(collection(db, "users"), data);
  
      //console.logog(userRef.id);
      sendEmail(notify, formRef, setShowSpinner, navigate)
      
      
    } catch (error) {
      setShowSpinner(false)
      console.error("Error creating user:", error);
      notify("Error creating user. Check network or try again later");
      // throw error;
    }

  } else {
    setShowSpinner(false)
    notify("Email is already in use")
  }
  
}

const sendEmail = (notify, formRef, setShowSpinner, navigate) => {
  emailjs
    .sendForm(
      "service_xazlyod", // Replace with your service ID
      "template_tz0ryej", // Replace with your template ID
      formRef,
      "HTl7DyvUw58VKUv0b" // Replace with your public key
    )
    .then((response) => {
      setShowSpinner(false)
      
      navigate('/login', { state: {from: 'signup' }})

      //console.logog("Email sent successfully:", response.status);
    })
    .catch((error) => {
      setShowSpinner(false)
      notify("Problem Creating Account. Contact Help Email");
      console.error("Failed to send email:", error);
    });
};

async function checkEmailExists(email) {
  const firestore = getFirestore();
  const usersRef = collection(firestore, 'users');

  try {
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error  checking email  existence:', error);
    return false;
  }
}

export { createUser };
