import React from 'react'
import {Header, Footer} from '../../_shared'
import Video from './Video'
import MainLanding from './MainLanding'
import WhyJoin from './WhyJoin'
import Domains from './Domains'
import Prizes from './Prizes'
import Timeline from './Timeline'
import Organizer from './Organizer';


function Landing() {
    return (
        <div className='landing'>
            <Header />
            <Video />
            <MainLanding />
            <WhyJoin />
            <Domains />
            <Prizes />
            <Timeline />
            <Organizer />
            <Footer />
        </div>
    )
}

export default Landing