import React from 'react'
import Logo from '../img/logo.png';
import { useNavigate } from 'react-router-dom';

function Header() {

  const navigate = useNavigate()

  return (
    <div className='header'>
        <div 
          className="header__logo"
          onClick={() => navigate('/')}
        >
            <img src={Logo} alt="Logo of Company" />
        </div>

        <div className="header__btnsection">
            <button 
              className='btn header__btn header__btn--register'
              onClick={() => navigate('/signup')}
            >
              Register
            </button>
            <button 
              className='btn header__btn header__btn--login'
              onClick={() => navigate('/login')}
            >
              Login
            </button>
        </div>
    </div>
  )
}

export default Header