import React from 'react'

function MainLanding() {
    return (
        <div className='landing__main' data-aos="fade-up" data-aos-duration="1500">

            <p className='landing__main__welcome'>Welcome to</p>
            <p className='landing__main__name'>Afro Innovation <span>2025</span></p>
            <p className='landing__main__slogan'>Showcase your brilliance:</p>
            <p className='landing__main__slogan__sub'>Win funding for your groundbreaking ideas</p>

            <div className="landing__main__paragraph">
                This is the inaugural edition of our innovation competition, designed to unearth and nurture brilliant minds.
                We are excited to witness the birth of groundbreaking ideas that have the potential to transform industries and society.
                Our goal is to foster a thriving entrepreneurial ecosystem by providing the necessary support and resources for participants to turn their concepts into successful ventures.
            </div>

            <div className="landing__main__paragraph">
                The competition offers a unique platform for students to showcase their innovative solutions to real-world challenges.
                A panel of esteemed investors will be in attendance to evaluate the pitches and explore potential investment opportunities.
                We believe that by connecting talented individuals with industry leaders,
                we can accelerate the growth of startups and create a positive impact on the economy.
                For any questions contact us via <span>support@afroinnovationsummit.com</span>
            </div>
            {
                /*
                <div className="landing__main__paragraph">
                The grand finale will be broadcast live on television, providing unprecedented exposure for the competing teams.
                This high-profile event will enable participants to connect with a global audience and build valuable professional networks.
                By empowering young minds and fostering entrepreneurship, we aim to contribute to job creation and economic development,
                ultimately addressing the pressing issue of unemployment in the country.
            </div>

                */
            }
            

            <div className="landing__main__btnsection">
                <button className='btn landing__main__btn'>Register</button>
            </div>
        </div>
    )
}

export default MainLanding