import React, { useState, useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import User from '../../img/user.jpg';
import { FaUserAstronaut } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { ClipLoader } from 'react-spinners';

const inlineStyles1 = {
    display: 'none',
    width: '0'
}

const inlineStyles2 = {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto'
}

function Chatroom(props) {

    const {message} = useContext(AppDataContext)

    function convertToDate(timestampObj) {
      
        // Create a Date object from the seconds
        const date = new Date(timestampObj.seconds * 1000);
      
        // Add the nanoseconds to the milliseconds
        const milliseconds = date.getMilliseconds() + (timestampObj.nanoseconds / 1000000);
      
        // Set the milliseconds on the Date object
        date.setMilliseconds(milliseconds);
      
        return date.toDateString();
      }

    return (
        <div
            className='chatroom'
            style={props.showChat ? {} : inlineStyles1}
        >
            <MdClose
                className='chatroom__close'
                onClick={() => props.closeChat()}
            />
            <h3>Conversations</h3>
            <h4>Afro Innovation 2025</h4>
            <div className="chatroom__container">

                
                        <div className="chatroom__message">
                            <div className="chatroom__message__avatar">
                                <img src={User} alt="Avatar of Creator of message" />
                            </div>
                            <div className="chatroom__message__content">
                                <div className="chatroom__message__content__head">
                                    <FaUserAstronaut className="chatroom__message__content__head__icon" />
                                    <span>Admin</span>
                                    <p>{convertToDate(message.date)}</p>
                                </div>
                                <div className="chatroom__message__content__text">
                                    {message.text}
                                </div>
                            </div>
                        </div>
                

            </div>
        </div>
    )
}

export default Chatroom
