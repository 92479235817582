import React from 'react'
import Rodal from 'rodal'
import { isMobile } from 'react-device-detect';

function Terms(props) {
    return (

        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.82 : 800}
            height={isMobile ? 540 : 670}
            customStyles={{ overflowY: 'scroll' }}
        >
            <div className='terms'>
                <h3>Terms of service</h3>

                <div className="terms__container">
                    <h4>1. Introduction</h4>

                    <p>
                        Welcome to AfroInnovationsSummit, a platform dedicated to fostering innovation and creativity within the African community. By accessing or using our website, services, or participating in our competitions, you agree to be bound by these Terms of Service. Please read these terms carefully before proceeding.
                    </p>


                    <h4>2. Eligibility</h4>

                    <p>To participate in AfroInnovationsSummit, you must be:</p>

                    <ul>
                        <li>At least 18 years old or the legal age of majority in your jurisdiction.</li>
                        <li>Residing in Africa or have a strong connection to the African continent.</li>
                        <li>Able to form a binding contract under applicable law.</li>
                    </ul>

                    <h4>3. User Conduct</h4>

                    <h5>Prohibited Activities: You agree not to:</h5>

                    <ul>
                        <li>Use our services for any unlawful or prohibited purpose.</li>
                        <li>Impersonate any person or entity.</li>
                        <li>Upload, transmit, or distribute any content that is harmful, offensive, or infringes on the rights of others.</li>
                        <li>Interfere with the operation of our website or services.</li>
                        <li>Attempt to gain unauthorized access to our systems or networks.</li>
                    </ul>

                    <h5>Intellectual Property:</h5>
                    <p>All content on our website, including but not limited to text, images, logos, and trademarks, is the property of AfroInnovationsSummit or its licensors. You may not reproduce, modify, distribute, or use this content without our prior written consent.</p>

                    <h4>4. Competition Rules</h4>

                    <ul>
                        <li>
                            <h5>Submission Requirements:</h5>
                            <span>All submissions must comply with the specific rules and guidelines outlined for each competition.</span>
                        </li>
                        <li>
                            <h5>Judging Criteria:</h5>
                            <span>Winners will be selected based on a combination of factors, including creativity, originality, potential impact, and adherence to competition guidelines.</span>
                        </li>
                        <li>
                            <h5>Prize Awarding:</h5>
                            <span>Prizes will be awarded at the sole discretion of AfroInnovationsSummit. We reserve the right to modify or cancel any competition at any time.</span>
                        </li>

                    </ul>



                    <h4>5. Limitation of Liability</h4>
                    <p>AfroInnovationsSummit shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services, even if we have been advised of the possibility of such damages.</p>
                    
                    <h4>6. Indemnification</h4>
                    <p>You agree to indemnify and hold AfroInnovationsSummit harmless from any claims, liabilities, damages, or losses arising out of or in connection with your use of our website or services, or your violation of these Terms of Service.</p>
                    
                    <h4>7. Governing Law and Jurisdiction</h4>

                    <p>These Terms of Service shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any dispute arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the courts of [Jurisdiction]</p>
                    
                    <h4>8. Amendments</h4>

                    <p>We may update these Terms of Service from time to time. Any changes will be effective upon posting on our website. Your continued use of our services after such changes indicates your acceptance of the amended terms.</p>
                    
                    <h4>9. Contact Information</h4>

                    <p>If you have any questions or concerns about these Terms of Service, please contact us at support@afroinnovationsummit.com or 0501430711.</p>
                    
                    <h5>By using our website or services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</h5>

                </div>

                <div className="terms__btnsection">
                    <button className="btn terms__btn" onClick={() => props.hideModal()}>Close</button>
                </div>
            </div>
        </Rodal>
    )
}

export default Terms