import React from 'react'
import Paul from '../../img/paul.jpg'
function Organizer() {
    return (
        <div className='landing__organizer'>

            <h3>ORGANIZER</h3>

            <div className="landing__organizer__container">
                <div className="landing__organizer__imgsection" data-aos="zoom-in" data-aos-duration="1500">
                    <img src={Paul} alt="the organizer of the event" />
                </div>
                <div className='landing__organizer__bio' data-aos="zoom-in" data-aos-duration="1500">
                    <p>
                        Dr. Paul Amissah is a prominent figure in the field of education and innovation, particularly within the context of African development.
                        He is the brain behind AfroInnovation Summit. an organization dedicated to fostering innovation in Africa.
                        He attended primary school at Martyrs of Uganda and proceeded to Opoku Ware school where he studied science.
                        He relocated to the U.S. after high school and got his B.S. in Human Biology from Syracuse University.
                        He holds Master of Biomedical Science and Doctor of medicine.
                        He is currently a Resident Doctor specializing in Foot and Ankle surgery.
                        He is an eager, results-driven individual who strives to achieve the best in every situation.
                        Dr. Amissah's work focuses on leveraging technology and innovative practices to improve educational outcomes and support entrepreneurial growth across the continent.
                        He is the CEO/Founder of Park4Me, LLC, a company based in NY which is in works to produce the first mobile App which uses infrared sensors to help find empty parking spots.
                        This initiative will be an annual event where we uncover the hidden gifts in our youth.
                        Main goal is to reduce over dependence on government jobs and push individuals with ideas to transform them into a company capable to employing ample people.
                        By encouraging entrepreneurship, we aim to create more jobs, reduce social vices, and spur robust economic development, allowing every individual to pursue and achieve their dreams.
                    </p>
                    <div className="landing__organizer__btnsection">
                        <button className='btn landing__organizer__btnsection__btn'>Connect With Me</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Organizer